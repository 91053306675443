<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="backToNative()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
      <div slot="right">
        <div class="header-menu">
          <div class="icon"
               @click="onSearch">
            <van-icon name="search" />
          </div>
          <div class="icon"
               @click="onAdd">
            <van-icon name="plus" />
          </div>
        </div>
      </div>
    </fb-header>
    <van-popup v-model="searchVisible"
               position="top"
               get-container="body">
      <van-search v-model="keyword"
                  placeholder="检索案件名称、当事人名字"
                  @input="searchInput" />
    </van-popup>
    <div class="oa-case">
      <filter-dropdown :filters="filters"
                       @select="handleFilterSelect"
                       @dateChange="handleFilterDateChange" />
      <!-- 案件列表 -->
      <div class="oa-case-list">
        <div class="oa-case-info"
             v-for="(item, index) in caseList"
             :key="index">
          <div class="oa-case-header">
            <div class="oa-case-title"
                 v-if="type === '0'">立案申请</div>
            <div class="oa-case-title"
                 v-if="type === '1'">结案申请</div>
            <div class="oa-case-userInfo">
              <img :src="item.avatarUrl"
                   alt="" />
              <div>{{ item.nickname }}</div>
            </div>
            <div class="oa-case-status">
              {{ caseStatusNameFmt(item.progress) }}
            </div>
          </div>
          <div class="oa-case-center">
            <div>{{ item.caseName }}</div>
          </div>
          <div class="oa-case-bottom">
            <div class="oa-case-time">申请时间：{{ item.useTime }}</div>
            <div class="oa-case-long">{{ item.useTimeText }}</div>
            <div class="oa-case-btn">
              <!-- <button v-for="(btnItem,btnIndex) in item.btnList"
                        :key="btnIndex"
                        @click.stop="btnClick(btnItem,item,index)">{{btnItem}}</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'
import FilterDropdown from '../../components/filterDropdown'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'oa-case',
  mixins: [goBackMixin],
  components: {
    FilterDropdown
  },
  data() {
    return {
      title: '案件管理',
      type: '0', // 0立案审批   1结案审批
      searchVisible: false, // 搜索弹框
      filters: [
        {
          label: '立案审批',
          id: 'type',
          options: [
            {
              text: '立案审批',
              value: '0'
            },
            {
              text: '结案审批',
              value: '1'
            }
          ]
        },
        {
          label: '案件类型',
          id: 'caseType',
          options: [
            {
              text: '诉讼案件',
              value: 'LITIGATION'
            },
            {
              text: '非诉讼案件',
              value: 'NON_LITIGATION'
            }
          ]
        },
        {
          label: '状态',
          id: 'progress',
          options: [
            {
              text: '待审批',
              value: 'UNAUDITED'
            },
            {
              text: '已审批',
              value: 'APPROVED'
            },
            {
              text: '已驳回',
              value: 'CERTIFIED_FAILED'
            }
          ]
        },
        {
          label: '创建时间',
          id: 'date',
          options: []
        }
      ],
      caseList: [], // 案件列表
      keyword: '',
      size: 10, // 每页条数
      page: 0, // 页码
      progress: '', // 筛选状态
      caseType: '', // 诉讼案件或者非诉讼案件
      startTime: '', // 开始时间
      endTime: '' // 结束时间
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    // 搜索按钮点击事件
    onSearch() {
      this.searchVisible = true
    },
    // 添加按钮点击事件
    onAdd() {
      this.$router.push({
        name: 'createcase',
        query: {
          isBackCaseManager: 1
        }
      })
    },
    // #筛选器# 选择处理
    handleFilterSelect(payload) {
      console.log('handleFilterSelect', payload)
      switch (payload.category.id) {
        case 'type':
          this.type = payload.option.value
          break
        case 'causeType':
          if (this.causeType === payload.option.value) {
            this.causeType = ''
          } else {
            this.causeType = payload.option.value
          }
          break
        case 'progress':
          if (this.progress === payload.option.value) {
            this.progress = ''
          } else {
            this.progress = payload.option.value
          }
          break
      }
      this.caseList = []
      this.page = 0
      this.isRequest = true
      this.getCaseList()
    },
    /* #筛选器-日期选择器# 改变处理
     * @param payload date类型
     */
    handleFilterDateChange(payload) {
      this.caseList = []
      this.startTime = payload[0]
      this.endTime = payload[1]
      this.page = 0
      this.isRequest = true
      this.getCaseList()
    },

    // 获取组织id
    async getId() {
      await this.getOrganizationId()
      this.getCaseList()
    },

    // 搜索功能
    searchInput() {
      this.caseList = []
      this.page = 0
      this.isRequest = true
      this.getCaseList()
    },

    // 获取案件列表
    getCaseList() {
      this.loading = true
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        size: this.size,
        page: this.page,
        keyword: this.keyword,
        progress: this.progress,
        userId: this.userInfo.id,
        caseType: this.caseType,
        userType: 'APPROVER',
        startTime: this.startTime,
        endTime: this.endTime
      }
      let url = ''
      if (this.type === '0') {
        url = `${this.$base}/management/legal/case/record/${this.organizationId}`
      } else {
        url = `${this.$base}/management/case/end/record/${this.organizationId}/organization`
        delete params.userType
      }
      this.$axios
        .get(url, {
          params
        })
        .then(res => {
          console.log(res)
          if (res.data.data) {
            if (res.data.data.totalElements === 0) {
              // 无数据显示缺省页面
              this.caseList = res.data.data.content
              this.defaultPage = 1
              this.isRequest = false
            } else {
              for (var i = 0; i < res.data.data.content.length; i++) {
                let avatarUrl = ''
                let nickname = ''
                res.data.data.content[i].approveRecordUsers.map((item, i) => {
                  if (item.type === 'APPLICANT') {
                    avatarUrl = item.user.avatarUrl
                    nickname = item.user.nickName
                  }
                })
                res.data.data.content[i].avatarUrl = avatarUrl
                res.data.data.content[i].nickname = nickname
                // 申请时间
                res.data.data.content[i].useTime = this.timestampToTime(
                  res.data.data.content[i].createTime
                )
                res.data.data.content[i].useTimeText = this.getDateDiff(
                  res.data.data.content[i].createTime
                )
              }
              this.caseList = [...this.caseList, ...res.data.data.content]
            }
          }
        })
    },

    // 案件状态格式化
    caseStatusNameFmt(code) {
      const dict = {
        UNSUBMENT: '未提交',
        UNAUDITED: '待审核',
        APPROVED: '审核通过',
        CERTIFIED_FAILED: '审核不通过'
      }
      return dict[code]
    },
    // 时间戳转换成时间
    timestampToTime(cjsj) {
      var date = new Date(cjsj) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var h =
        ' ' +
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
        ':'
      var m =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return Y + M + D + h + m
    },

    // 申请时间格式化
    getDateDiff(dateTimeStamp) {
      var result = ''
      var minute = 1000 * 60
      var hour = minute * 60
      var day = hour * 24
      var now = new Date().getTime()
      var diffValue = now - dateTimeStamp
      var dayC = diffValue / day
      var hourC = diffValue / hour
      var minC = diffValue / minute
      if (dayC >= 1) {
        result = '' + parseInt(dayC) + '天前'
      } else if (hourC >= 1) {
        result = '' + parseInt(hourC) + '小时前'
      } else {
        result = '' + parseInt(minC) + '分钟前'
      }
      return result
    }
  },
  async created() {
    await this.getId()
  },
  mounted() {}
}
</script>

<style lang="stylus">
.oa-case
  position relative
.oa-case-list
  padding 0 10px
  box-sizing border-box
  & .oa-case-info
    border-radius 5px
    border 1px solid rgba(204, 204, 204, 1)
    width 100%
    height auto
    padding 10px 0
    box-sizing border-box
    margin-bottom 10px
    & .oa-case-header
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      padding 0 10px
      box-sizing border-box
    & .oa-case-title
      font-size 14px
      color #666666
    & .oa-case-userInfo
      font-size 14px
      display flex
      flex-direction row
      align-items center
      img
        width 24px
        height 24px
        margin-right 5px
        border-radius 12px
    & .oa-case-status
      font-size 16px
    & .oa-case-center
      font-size 16px
      font-weight bold
      box-sizing border-box
      padding 10px 15px
      border-bottom 1px solid rgba(204, 204, 204, 1)
      div
        display -webkit-box
        -webkit-box-orient vertical
        -webkit-line-clamp 2
        overflow hidden
    & .oa-case-bottom
      display flex
      flex-direction row
      align-items center
      box-sizing border-box
      padding 10px 10px 0 10px
      & .oa-case-time
        font-size 12px
        color #999999
        margin-right 10px
      & .oa-case-btn
        display flex
        flex-direction row
        align-items center
        justify-content flex-end
        flex 1
        button
          width 55px
          height 30px
          border-radius 5px
          border 1px solid rgba(0, 121, 254, 1)
          background none
          color #0079FE
          font-size 14px
</style>
